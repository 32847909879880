import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function WelcomePage() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('/api/news');
        if (!response.ok) {
          const errorBody = await response.text();
          throw new Error(`Failed to fetch news: ${response.status} ${response.statusText} - ${errorBody}`);
        }
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error('Error fetching news:', error.message, error.stack);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="p-5">
      <h2 className="text-2xl font-bold mb-4">Welcome to Astralis Portal</h2>
      <section className="mb-5">
        <h3 className="text-xl font-semibold">Latest News and Updates</h3>
        <ul>
          {news.map((item) => (
            <li key={item._id} className="mt-3">
              <h4 className="text-lg font-bold">{item.title}</h4>
              <p>{item.content}</p>
              <p className="text-sm text-gray-600">Published on: {new Date(item.publicationDate).toLocaleDateString()}</p>
            </li>
          ))}
        </ul>
      </section>
      <section className="mb-5">
        <div className="bg-gray-200 p-5 text-gray-700">Advertisements</div>
      </section>
      <section className="mb-5">
        <h3 className="text-xl font-semibold">Quick Links</h3>
        <div className="flex flex-wrap justify-center gap-4 mt-4">
          <Link to="/store" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Visit Store
          </Link>
          <Link to="/register" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Register
          </Link>
          <Link to="/login" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Login
          </Link>
        </div>
      </section>
    </div>
  );
}

export default WelcomePage;