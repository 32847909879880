import React from 'react';
import { useAppContext } from '../AppContext';
import Product from './Product'; // Assuming Product component is correctly implemented as per instructions

function Store() {
  const { products, fetchProducts, addToCart } = useAppContext();

  React.useEffect(() => {
    fetchProducts().catch(error => console.error('Error fetching products:', error.message, error.stack));
  }, [fetchProducts]);

  return (
    <div className="p-5">
      <h2 className="text-2xl font-bold mb-4">Digital Goods Store</h2>
      <div className="grid grid-cols-3 gap-4">
        {products.map((product) => (
          <Product 
            key={product._id} 
            product={product} 
            addToCart={addToCart}
          />
        ))}
      </div>
      {products.length === 0 && <p>No products found.</p>}
    </div>
  );
}

export default Store;