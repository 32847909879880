import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);

  const fetchProducts = async () => {
    try {
      const response = await fetch('/api/store');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProducts(data);
      console.log('Products fetched successfully');
    } catch (error) {
      console.error('Error fetching products:', error.message, error.stack);
    }
  };

  const addToCart = (product) => {
    setCart([...cart, product]);
    console.log(`Product ${product.title} added to cart`);
  };

  const removeFromCart = (productId) => {
    setCart(cart.filter((product) => product._id !== productId));
    console.log(`Product removed from cart`);
  };

  const clearCart = () => {
    setCart([]);
    console.log(`Cart cleared`);
  };

  const value = {
    products,
    fetchProducts,
    cart,
    addToCart,
    removeFromCart,
    clearCart,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};