import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { fetchFromAPI } from '../helpers/api'; // Assuming fetchFromAPI is a helper function to make API requests


// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe('pk_test_51OMQGIBeEAIKKTqnYd9yPX4Y2V2aS7aZ2Wd0ESNPOSRfnaGVp46fpCj2YbzFq3KKIsrztHt9VU7geaeEl65lFRtU00SLWLXd8e');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [processing, setProcessing] = useState(false);
  


  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetchFromAPI('payment/create-payment-intent', {
      method: 'POST',
      body: JSON.stringify({
        // Assuming the backend expects an "items" array to calculate the payment amount
        items: [{ id: "prod_HG31qMzDFJsdhq", quantity: 1 }],
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data => {
        setClientSecret(data.clientSecret);
      })
      .catch(error => {
        console.error('Error creating payment intent:', error.message, error.stack);
        setError('Failed to initialize payment. Please try again.');
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || !clientSecret) {
      console.error('Stripe.js has not loaded yet or client secret is missing.');
      return;
    }

    setProcessing(true);
    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (error) {
        console.error('Error processing payment:', error.message);
        setError(error.message);
        setProcessing(false);
      } else if (paymentIntent) {
        console.log('Payment successful:', paymentIntent.id);
        setError(null);
        setProcessing(false);
        // Handle successful payment here (e.g., display confirmation message)
        alert('Payment successful!');
      }
    } catch (err) {
      console.error('Payment processing error:', err.message, err.stack);
      setError('Payment processing error. Please try again.');
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || processing}>Pay</button>
      {error && <div>{error}</div>}
    </form>
  );
};

const Checkout = () => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: '{{CLIENT_SECRET}}',
  };

  return (
    <>
  <div>
    testing: {process.env.REACT_APP_STRIPE_PUBLIC_KEY}

  </div>

<Elements stripe={stripePromise} >
      <CheckoutForm />
    </Elements>
    </>
  );
};

export default Checkout;