import React from 'react';
import { useAppContext } from '../AppContext';

function Cart() {
  const { cart, removeFromCart, clearCart } = useAppContext();

  const handleRemoveFromCart = (productId) => {
    try {
      removeFromCart(productId);
      console.log(`Product ${productId} removed from cart`);
    } catch (error) {
      console.error('Error removing product from cart:', error.message, error.stack);
    }
  };

  const handleClearCart = () => {
    try {
      clearCart();
      console.log(`Cart cleared`);
    } catch (error) {
      console.error('Error clearing cart:', error.message, error.stack);
    }
  };

  return (
    <div className="p-5">
      <h2 className="text-2xl font-bold mb-4">Your Cart</h2>
      {cart.length > 0 ? (
        <div>
          <ul>
            {cart.map((product) => (
              <li key={product._id} className="mb-4">
                <div className="flex justify-between items-center">
                  <span>{product.title}</span>
                  <span>${product.price.toFixed(2)}</span>
                  <button
                    onClick={() => handleRemoveFromCart(product._id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <button
              onClick={handleClearCart}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Clear Cart
            </button>
          </div>
        </div>
      ) : (
        <p>Your cart is empty.</p>
      )}
    </div>
  );
}

export default Cart;