import React from 'react';
import { useAppContext } from '../AppContext';

function Product({ product }) {
  const { addToCart } = useAppContext();

  const handleAddToCart = () => {
    try {
      addToCart(product);
      console.log(`Product ${product.title} added to cart`);
    } catch (error) {
      console.error('Error adding product to cart:', error.message, error.stack);
    }
  };

  return (
    <div className="border rounded-lg shadow-lg p-4 flex flex-col items-center">
      <img src={product.imageUrl} alt={product.title} className="w-1/2 mb-4" />
      <h3 className="text-lg font-bold">{product.title}</h3>
      <p className="text-gray-600">{product.description}</p>
      <p className="font-semibold mt-2">${product.price.toFixed(2)}</p>
      <button
        onClick={handleAddToCart}
        className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Add to Cart
      </button>
    </div>
  );
}

export default Product;