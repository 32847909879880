import React, {useCallback, useState, useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';
import { AppProvider } from './AppContext';
import WelcomePage from './components/WelcomePage';
import Store from './components/Store';
import Registration from './components/Registration';
import Login from './components/Login';
import Cart from './components/Cart';
import Checkout from './components/Checkout'; // Import Checkout component
import StripeTest from './components/StripeTest'; // Import StripeTest component\

import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Navigate
} from "react-router-dom";

import "./App.css";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe("pk_test_51OMQGIBeEAIKKTqnYd9yPX4Y2V2aS7aZ2Wd0ESNPOSRfnaGVp46fpCj2YbzFq3KKIsrztHt9VU7geaeEl65lFRtU00SLWLXd8e");

const CheckoutForm = () => {
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    fetch("/create-checkout-session", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
  }, []);

  const options = {fetchClientSecret};

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(`/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
  }, []);

  if (status === 'open') {
    return (
      <Navigate to="/checkout" />
    )
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    )
  }

  return null;
}

function App() {
  console.log("App component loaded and rendering routes");
  return (
    <AppProvider>
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route path="/store" element={<Store />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/return" element={<Return />} />
      </Routes>
    </AppProvider>
  );
}

export default App;